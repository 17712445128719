<!-- 审核 -->
<template>
  <div class="diseaseList">
    <!-- TODO:封装selectForm筛选 -->
    <el-form :inline="true">
      <!-- <el-button type="primary" size="small" v-waves @click="handleAdd"
        >新增</el-button
      > -->
      <el-form-item
        label="所属门店:"
        style="margin:20rpx 20rpx 0 20rpx"
        size="small"
      >
        <el-select
          v-model="listQuery.store_id"
          placeholder="请选择"
          clearable
          multiple
          collapse-tags
        >
          <div v-for="(item, index) in filterStoreList" :key="index">
            <el-option
              :label="item.store_name"
              :value="item.store_id + ''"
            ></el-option>
          </div>
        </el-select>
      </el-form-item>
      <el-form-item
        label="订单状态:"
        style="margin:20rpx 20rpx 0 20rpx"
        size="small"
      >
        <el-select v-model="listQuery.status" placeholder="请选择" clearable>
          <div v-for="(item, index) in stateList" :key="index">
            <el-option :label="item.name" :value="item.id"></el-option>
          </div>
        </el-select>
      </el-form-item>
      <!-- <el-form-item
        label="订单类型:"
        style="margin:20rpx 20rpx 0 20rpx"
        size="small"
      >
        <el-select
          v-model="listQuery.type"
          placeholder="请选择"
          style="width: 100%;"
          clearable
        >
          <div v-for="(item, index) in typeList" :key="index">
            <el-option :label="item.name" :value="item.id"></el-option>
          </div>
        </el-select>
      </el-form-item> -->
      <el-form-item
        label="手机号: "
        style="margin:20rpx 20rpx 0 20rpx"
        size="small"
      >
        <el-input
          v-model="listQuery.phone"
          placeholder="请输入商品名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-button
        class="addBtn"
        type="primary"
        @click="getListData"
        size="small"
        v-waves
        >搜索</el-button
      >
    </el-form>
    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="status" slot-scope="scope">
          <!-- <FillingStatus :value="scope.row.status" /> -->
          {{
            scope.row.status === 1
              ? "待支付"
              : scope.row.status === 2
              ? "进行中"
              : scope.row.status === 3
              ? "已完成"
              : scope.row.status === 4
              ? "退款中"
              : scope.row.status === 5
              ? "已退款"
              : "/"
          }}
        </template>
        <template slot="payStyle" slot-scope="scope">
          <el-tag effect="dark" size="mini">
            {{
              scope.row.payStyle == 1
                ? "微信支付"
                : scope.row.payStyle == 2
                ? "会员卡支付"
                : "/"
            }}
          </el-tag>
        </template>

        <template slot="type" slot-scope="scope">
          <el-tag effect="dark" size="mini">
            {{
              scope.row.type === 1
                ? "网站订单"
                : scope.row.type === 2
                ? "小程序订单"
                : scope.row.type === 3
                ? "积分商城"
                : ""
            }}
          </el-tag>
        </template>

        <template slot="logisticsNumber" slot-scope="scope">
          {{
            scope.row.logisticsNumber
              ? scope.row.logisticsNumber
              : "暂无物流单号"
          }}
        </template>
        <template slot="detail" slot-scope="scope">
          <div>
            <el-tag effect="dark" size="mini">
              取货号:{{ scope.row.takeCode ? scope.row.takeCode : "暂无" }}
            </el-tag>
            <!-- <el-tag effect="dark" size="mini" style="margin-left: 20px;">
              桌号:{{ scope.row.tableNumber ? scope.row.tableNumber : "暂无" }}
            </el-tag> -->
          </div>
          <div
            v-for="(item, index) in scope.row.productList"
            :key="index"
            style="margin: 5px 0;"
          >
            {{
              item.productName +
                (item.sku ? "(" + item.sku + ")" : "") +
                item.price
            }}
            X
            {{ item.num }}
          </div>
        </template>

        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button
              style="color: #afb86d"
              size="mini"
              type="text"
              @click="handleEdit(row)"
              primary
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="handleInvoiceInfo(row)"
              primary
              v-if="row.status === 3"
              >{{ row.invoiceUrl ? "已开票" : "开票" }}</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="handleDelete(row)"
              style="color: rgb(255, 0, 0);"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>
    </div>
    <!-- 弹窗表单 -->
    <el-dialog
      :title="dialogName"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="dialogForm" label-width="80px">
        <el-form-item label="订单状态:">
          <el-select
            v-model="dialogForm.status"
            placeholder="请选择"
            style="width: 100%;"
            clearable
          >
            <div v-for="(item, index) in stateList" :key="index">
              <el-option :label="item.name" :value="item.id"></el-option>
            </div>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="订单类型:">
          <el-select
            v-model="dialogForm.type"
            placeholder="请选择"
            style="width: 100%;"
            clearable
          >
            <div v-for="(item, index) in typeList" :key="index">
              <el-option :label="item.name" :value="item.id"></el-option>
            </div>
          </el-select>
        </el-form-item> -->
        <el-form-item label="订单备注">
          <el-input
            type="textarea"
            clearable
            v-model="dialogForm.odescribe"
          ></el-input>
        </el-form-item>
        <el-form-item label="物流单号">
          <el-input clearable v-model="dialogForm.logisticsNumber"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button class="addBtn" size="small" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="开票"
      :visible.sync="invoiceInfoVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="invoiceInfo" label-width="90px">
        <el-form-item label="开票抬头" required>
          <el-input v-model="invoiceInfo.title" clearable disabled></el-input>
        </el-form-item>
        <el-form-item label="税号" required>
          <el-input
            v-model="invoiceInfo.taxNumber"
            clearable
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" required>
          <el-input
            v-model="invoiceInfo.telephone"
            clearable
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="开户行" required>
          <el-input
            v-model="invoiceInfo.bankName"
            clearable
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账户" required>
          <el-input
            v-model="invoiceInfo.bankAccount"
            clearable
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="地址" required>
          <el-input
            v-model="invoiceInfo.companyAddress"
            clearable
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="发票链接" width="100" required>
          <el-input v-model="dialogForm.invoiceUrl" clearable></el-input>
        </el-form-item>
        <el-form-item label="上传发票" required>
          <el-upload
            class="upload-demo"
            :action="action"
            :multiple="true"
            :on-success="handleSuccess_cover"
            :file-list="fileList"
            list-type="picture"
            name="formData"
          >
            <el-button size="small" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" @click="invoiceInfoVisible = false">取 消</el-button>
        <el-button type="primary" @click="invoiceInfoSave">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { IP } from "@/services/config.js";
import ElTable from "@/components/elTable/index.vue";
import listHeader from "./listHeader";
// import filterQueryFormItems from "./filterQueryFormItems";
import Percentage from "@/components/filters/Percentage.vue";
import FillingStatus from "@/components/filters/FillingStatus.vue";
import KindTab from "@/components/tab/kindTab/index.vue";
// import waves from "@/directive/waves/index.js"; // 水波纹指令
import {
  updateOrder,
  selectOrder,
  deleteOrder,
  updateInvoiceUrl,
} from "@/services/mall/order";
import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    Percentage,
    FillingStatus,
    KindTab,
  },
  async created() {
    this.getListData();
  },
  // directives: {
  //   waves,
  // },
  data() {
    return {
      loading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      /* 表格数据 */
      tableData: [],
      /* 弹窗 */
      dialogName: "",
      dialogVisible: false,
      dialogForm: {},
      stateList: [
        { id: 0, name: "全部" },
        { id: 1, name: "待支付" },
        { id: 2, name: "进行中" },
        { id: 3, name: "已完成" },
        { id: 4, name: "退款中" },
        { id: 5, name: "已退款" },
      ],
      typeList: [
        { id: 1, name: "门店订单" },
        { id: 2, name: "微商城" },
        { id: 3, name: "积分商城" },
      ],
      invoiceInfoVisible: false,
      invoiceInfo: {},
      action: IP + "/uploadFile/add",
      fileList: [],
    };
  },
  computed: {
    // filterQueryFormItems,
    listHeader,
    ...mapState(["userInfo", "filterStoreList"]),
  },
  watch: {},
  methods: {
    async getListData() {
      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.store_id =
        listQuery.store_id?.join(",") || this.userInfo.store_id;
      this.loading = true;
      const { data, code, count } = await selectOrder(listQuery);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    /* 搜索按钮 */
    async search(listQuery) {
      console.log("listQuery", listQuery);
      this.listQuery = listQuery;
      this.getListData();
    },

    /* 新增 */
    handleAdd() {
      this.dialogVisible = true;
      this.dialogName = "创建订单";
      this.dialogForm = {};
    },

    /* 修改 */
    handleEdit(row) {
      console.log(row);
      this.dialogVisible = true;
      this.dialogName = "编辑订单";
      this.dialogForm = JSON.parse(JSON.stringify(row));
    },

    /* 保存 */
    save() {
      if (this.dialogName === "创建订单") {
        // addOrder(this.dialogForm).then((res) => {
        //   console.log("创建订单", res);
        //   if (res.code === 200) {
        //     this.listQuery.pageNumber = 1;
        //     this.getListData();
        //     this.$message({
        //       type: "success",
        //       message: "创建成功!",
        //     });
        //     this.dialogVisible = false;
        //   }
        // });
      }
      if (this.dialogName === "编辑订单") {
        updateOrder(this.dialogForm).then((res) => {
          console.log("编辑订单", res);
          if (res.code === 200) {
            this.getListData();
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.dialogVisible = false;
          }
        });
      }
    },

    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm("此操作将永久删除该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row.id);
          deleteOrder({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },

    /* 分页 */
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    /* 开票 */
    handleInvoiceInfo(row) {
      if (!row.invoiceInfo) {
        this.$message.error("请通知用户上传开票信息");
        return;
      }
      this.invoiceInfo = JSON.parse(row.invoiceInfo);
      this.dialogForm = JSON.parse(JSON.stringify(row));
      this.invoiceInfoVisible = true;
      this.fileList = [];
    },
    handleSuccess_cover(e) {
      this.dialogForm.invoiceUrl = e.data;
      this.fileList = [];
    },
    invoiceInfoSave() {
      updateInvoiceUrl({
        orderNumber: this.dialogForm.orderNumber,
        invoiceUrl: this.dialogForm.invoiceUrl,
      }).then((res) => {
        if (res.code === 200) {
          this.invoiceInfoVisible = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getListData();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">


.diseaseList {
  width: 100%;
}
.selectForm {
  background: #fff;
  // max-width: 1405px;
  padding: 10px 0;
  margin: 10px auto;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}
</style>
