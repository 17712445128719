export default function() {
  /* 表格列头 */
  return [
    // { label: "序号", type: "index", width: 70 },
    { label: "类型", prop: "type", slots: "type", width: 100 },
    { label: "订单编号", prop: "orderNumber", width: 200 },
    { label: "状态", prop: "status", slots: "status", width: 100 },
    { label: "手机号", prop: "phone", width: 150 },
    {
      label: "支付方式",
      prop: "payStyle",
      slots: "payStyle",
      width: 100,
    },
    // { label: "openid", prop: "openid" },
    { label: "金额", prop: "cost", width: 100 },
    { label: "实际支付", prop: "realityCost", width: 100 },
    { label: "明细", prop: "detail", slots: "detail", width: 240 },
    { label: "备注", prop: "odescribe", width: 200, },
    {
      label: "物流单号",
      prop: "logisticsNumber",
      width: 200,
      slots: "logisticsNumber",
    },
    {
      label: "创建时间",
      prop: "createTime",
      width: 180,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 120,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
